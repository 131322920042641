import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { DefaultLayout } from "../layouts";
import { white, veryLightGrey, veryLightBlue } from "../utils";
import { Header, Background, PageWrapper } from "../components";

const Map = styled.div`
  flex-basis: 400px;
  flex-grow: 1;
  flex-basis: 48%;
  margin: 10px;
  display: flex; 
  @media(min-width: 1000px) {
    justify-content: start;
  }
  @media(max-width: 999px) {
    justify-content: center;
  }
`;

const Box = styled.div`
  align-self: center;
  justify-self: center;
  flex-basis: 80%;
  max-width: 820px;

  & p {
    padding: 5px;
    margin: 10px 0;
  }

  & a {
    padding: 5px;
    display: block;
    margin: 10px 0;
  }
`;

const ContactBox = styled(Box)`
  margin: 10px;
  flex-grow: 1; 
  flex-basis: 48%;
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    flex-basis: 400px;
    padding: 5px;
    border: solid 1px rgb(89,89,89, 0.3);
  }
  @media(min-width: 1000px) {
    justify-content: flex-end;
  }
  @media(max-width: 999px) {
    justify-content: center;
  }
`;

const BoxHeader = styled.h2`
  margin: 0 0 10px 0;
  background-color: rgb(89,89,89, 0.75);
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14pt;
  color: white;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
`;

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let contact = this.props.data.contact.details;

    return (
      <DefaultLayout title="Contact Us">
        <Background image={this.props.data.logo.publicURL}>
          <Header color={white}>{this.props.data.contact.header}</Header>
        </Background>
        <PageWrapper>
          <div>
            <Container>
              <ContactBox>
                <div>
                  <BoxHeader>Contact Details</BoxHeader>
                  <p>{contact.addressLine1}</p>
                  <p>{contact.addressLine2}</p>
                  <a href={`tel:${contact.number}`}>
                    {contact.number}
                  </a>
                  <a href={`mailto:${contact.email}`}>
                    {contact.email}
                  </a>
                </div>
              </ContactBox>
              <Map>
                <iframe
                  title="map"
                  width="400"
                  height="345"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBI1Xx0swvVCeRuyZK19d-6SI61AXQiwms&q=Stratton Street Capital LLP,200 Aldersgate Street,London,EC1A 4HD"
                  allowFullScreen>
                </iframe>
              </Map>
            </Container>
          </div>
        </PageWrapper>
      </DefaultLayout>
    );
  }
}

export const query = graphql`
  {
    contact: contactJson {
      header
      details {
        addressLine1
        addressLine2
        number
        email
      }
    }
    logo: file(name: { eq: "contact-us" }) {
      publicURL
    }
  }
`;

export default ContactPage;
